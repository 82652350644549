const initCreditCard = () => {
  if (!window?.AppContext?.stripeClientSecret) return;
  if (!Stripe) return;

  const stripe = Stripe(window.AppContext.stripeKey);

  const options = {
    clientSecret: window.AppContext.stripeClientSecret,
    appearance: {},
  };

  const elements = stripe.elements(options);
  const paymentElement = elements.create('payment');
  paymentElement.mount('#payment-element');

  // After submit
  const form = document.getElementById('payment-form');
  const submitBtn = document.getElementById('submit');

  form.addEventListener('submit', async (event) => {
    event.preventDefault();

    submitBtn.innerText = 'Saving details...';

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: Routes.confirm_app_settings_payment_methods_url(),
      },
    });

    if (error) {
      window.Toaster.showAlert(error.message);
    }

    submitBtn.innerText = 'Save details';
  });
};

document.addEventListener('DOMContentLoaded', initCreditCard, false);
// document.addEventListener('turbolinks:load', initCreditCard, false);
