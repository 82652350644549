import axios from 'axios';

const initPayeeForm =  () => {
  const $form = document.querySelector('.payee-form');
  const $entityTypeSelect = document.querySelector("#payee_entity_type");
  const $countrySelect = document.querySelector("#payee_country");
  if ($countrySelect) {

    $entityTypeSelect.addEventListener("change", (event) => {
      const value = event.target.value;
      $form.setAttribute('data-type', value);
    });

    $countrySelect.addEventListener("change", (event) => {
      const value = event.target.value;
      $form.setAttribute('data-country', value);
      axios.get(Routes.api_country_url({id: value}))
        .then((response) => {
          $form.setAttribute('data-eu', response.data.country.in_eu);
        })
    })

  }
};

// document.addEventListener('turbolinks:load', initPayeeForm, false);
document.addEventListener('DOMContentLoaded', initPayeeForm, false);
