import "@/utils/sentry";

// 3rd party JS
import "../polyfills";

import Rails from "@rails/ujs";
import Sugar from "sugar";
import "sugar-inflections";
import "@/helpers/location";
import "react-dates/initialize";

import "../utils/jquery";
import "../utils/lodash";
import "../utils/dates";

// 3rd party CSS
import "../utils/fonts";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "basicgrid/dist/basicGrid.min.css";
import "react-dates/lib/css/_datepicker.css";
import "../utils/windi_css";
import "../stylesheets/style.scss";

import "../utils/flashes";
import "../utils/payment_methods";
import "../utils/cocoon";
import "../utils/payee_form";

import { Turbo } from "@hotwired/turbo-rails";

import "../utils/routes";
import "../utils/axios";

import "../controllers";
import "../components";

// Cable
import "../utils/cable";

Turbo.session.drive = false;

Sugar.extend({
	namespaces: [String, Array],
});

document.addEventListener("DOMContentLoaded", () => {
	if (!window._rails_loaded) {
		Rails.start();
	}
});
